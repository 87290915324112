import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout/Layout';
import Sidebar from '../components/Sidebar/Sidebar';

const NotFoundRoute = (props) => {
  return (
    <Layout>
      <div>
        <Sidebar {...props} lang="en" />
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <h1 className="page__title">NOT FOUND</h1>
              <div className="page__body">
                <p>You just hit a route that doesn&#39;t exist...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundRoute;

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        author {
          name
          twitter
          github
          rss
        }
      }
    }
  }
`;
